@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700");

* {
  margin: 0;
  padding: 0;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
